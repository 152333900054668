<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="vips" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import ruleSchemas from './vip-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter
    },
    computed: {
      vips() {
        let rows = this.$store.state.vips;
        return dtHelper.filterByRules(rows, this.filterOutput);
      }
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(ruleSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(ruleSchemas)
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchVip');
      this.$store.dispatch('setPageTitle', 'VIP');
      this.$store.dispatch('setCurrentActions', [])
    },
    methods: {

      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },

      edit(index, row){
        this.$router.push('/vip/' + row.id);
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
