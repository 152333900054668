export default [
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 80,
    type: 'text'
  },
  {
    prop: 'discount',
    label: 'Giảm giá (%)',
    minWidth: 250,
    type: 'number'
  },
  {
    prop: 'max_value_discount',
    label: 'Giảm tối đa',
    minWidth: 250,
    type: 'number'
  }
]
